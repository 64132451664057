import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CSidebarBrand,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Blublu from '../../../lib/blublu'

import { signInWithCredential, getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

export default class Login extends React.Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
            loginSuccess: false,
            loggedOut: false,
        }
    }

    componentDidMount() {
        console.log(this.props)
        let destroy = new URLSearchParams(this.props.location.search).get('destroy')
        if (destroy == 'true') {
            return this.doLogOut()
        }

        //console.log("DESTROY", destroy)
    }

    async doGoogleLogin() {
        let auth = getAuth()
        let provider = new GoogleAuthProvider()
        let result = await signInWithPopup(auth, provider)

        const credential = GoogleAuthProvider.credentialFromResult(result)
        //const token = credential.accessToken;
        // The signed-in user info.

        let firebaseUserCredential = await signInWithCredential(auth, credential)

        const user = firebaseUserCredential.user.toJSON()
        let idToken = await auth.currentUser.getIdToken(true)

        /*
        const credential = GoogleAuthProvider.credential(result._tokenResponse.idToken, result._tokenResponse.accessToken);


        const idToken = await auth.currentUser.getIdToken(true);
        let user = firebaseUserCredential.user.toJSON();
         */

        let authArgs = {
            user,
            idToken,
            app_id: 'hoodsie',
        }

        let fbResult = await Blublu.post('firebase/auth', authArgs)
        if (fbResult.success) {
            await Blublu.setStore('username', user.email)
            await Blublu.setStore('token', fbResult.token)
            await Blublu.setStore('providers', fbResult.providers || [])
            await Blublu.setStore('globalAccess', fbResult.access || [])

            if (fbResult.providers && fbResult.providers.length > 0) {
                await Blublu.setCurrentProvider(fbResult.providers[0])

                //let appIds = fbResult.providers[0]?.app_ids;
                //if (appIds && appIds.length > 0) {
                let configResult = await Blublu.get('public/app-config/hoodsie')
                if (configResult.success) {
                    await Blublu.setStore('appId', 'hoodsie')
                    await Blublu.setStore('config', configResult.config)
                }
                //}
                // load app config
            } else {
                return alert('You are not assigned to any merchants')
            }
            this.setState({ loginSuccess: true })
        } else {
            alert('Invalid login')
        }
    }

    async doLogOut() {
        await Blublu.setStore('token', null)
        //this.setState({loggedOut: true});
    }

    async doLogin() {
        let args = {
            username: this.state.username,
            password: this.state.password,
        }
        let result = await Blublu.post('account/login', args)
        if (result.success) {
            await Blublu.setStore('username', this.state.username)
            await Blublu.setStore('token', result.token)
            await Blublu.setStore('providers', result.providers || [])
            await Blublu.setStore('globalAccess', result.access || [])

            if (result.providers && result.providers.length > 0) {
                await Blublu.setCurrentProvider(result.providers[0])
                const appId = 'hoodsie'
                let configResult = await Blublu.get('public/app-config/' + appId)
                if (configResult.success) {
                    await Blublu.setStore('appId', appId)
                    await Blublu.setStore('config', configResult.config)
                }
                // load app config
            } else {
                return alert('You are not assigned to any merchants')
            }
            this.setState({ loginSuccess: true })
        } else {
            alert('Invalid login')
        }
    }

    updateUsername(ev) {
        console.log('>>>>>')
        this.setState({ username: ev.target.value })
    }

    updatePassword(ev) {
        this.setState({ password: ev.target.value })
    }

    showResetPassword() {
        this.props.history.push('reset-password')
    }

    render() {
        if (this.state.loginSuccess) {
            return <Redirect to="/" push={true} />
        }
        return (
            <div className="c-app c-default-layout flex-row align-items-center">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md="8">
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm>
                                            <h1>Login</h1>
                                            <p className="text-muted">Sign In to your account</p>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon name="cil-user" style={{ width: 20, height: 20 }} />
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput
                                                    type="text"
                                                    placeholder="Email"
                                                    autoComplete="username"
                                                    value={this.state.username}
                                                    onChange={(value) => this.updateUsername(value)}
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon
                                                            name="cil-lock-locked"
                                                            style={{ width: 20, height: 20 }}
                                                        />
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput
                                                    type="password"
                                                    placeholder="Password"
                                                    autoComplete="current-password"
                                                    value={this.state.password}
                                                    onChange={(value) => this.updatePassword(value)}
                                                />
                                            </CInputGroup>
                                            <CRow>
                                                <CCol xs="12" className="text-center">
                                                    <CButton
                                                        style={{ width: 220 }}
                                                        onClick={() => this.doLogin()}
                                                        color="primary"
                                                        className="px-4"
                                                    >
                                                        Login
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol style={{ height: 20 }}></CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol xs="12" className="text-center">
                                                    <img
                                                        src={'./images/google-login.png'}
                                                        style={{ maxWidth: 225 }}
                                                        onClick={() => this.doGoogleLogin()}
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol style={{ height: 15 }}></CCol>
                                            </CRow>

                                            <CRow>
                                                <CCol xs="12" className="text-center">
                                                    <CButton
                                                        color="link"
                                                        onClick={() => this.showResetPassword()}
                                                        className="px-0"
                                                    >
                                                        Forgot password?
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                                <CCard className="text-black bg-secondary py-5 d-md-down-none" style={{ width: '44%' }}>
                                    <CCardBody className="text-center">
                                        <div>
                                            <h2>Login</h2>

                                            <img
                                                src="./images/blublu.png"
                                                className="img-fluid"
                                                style={{ maxHeight: 25 }}
                                            />
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        )
    }
}
